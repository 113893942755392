

























































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Burs Başvurusu"
  }
})
export default class ProjectsView extends Vue {
  tab = null;
}
